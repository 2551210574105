(function() {
  'use strict';

  // todo: all forms need to be turned to formly
  function EventTypeSectionController(
    $q,
    $state,
    $stateParams,
    $scope,
    Blueprints,
    EventType,
    EventTypes,
    Relations,
    Roles,
    Form,
    Notify,
    Utils
  ) {
    var ctrl = this;

    // setup event type
    ctrl.eventType = {};
    ctrl.section = {};

    /**
     * @param {_.List<T>} fields
     * set a list of the dependent object's names by object id
     * @return
     */
    function preLoadExternalFieldNames(fields) {
      var fieldTypes = {};
      _.forEach(fields, function(field) {
        if (_.indexOf(['blueprint', 'relation', 'role'], field.type) === -1) {
          return;
        }

        if (_.isUndefined(fieldTypes[field.type])) {
          fieldTypes[field.type] = [];
        }

        fieldTypes[field.type].push(field[field.type]);
      });

      var prom = [];
      _.forEach(fieldTypes, function(ids, fieldType) {
        var serviceMap = { blueprint: Blueprints, relation: Relations, role: Roles };
        prom.push(serviceMap[fieldType].findByIds(ids));
      });

      $q.all(prom)
        .then(function(result) {
          ctrl.preLoadedExternalFieldNames = {};
          _.forEach(result, function(listOfObjectsOfTheSameType) {
            _.forEach(listOfObjectsOfTheSameType, function(obj) {
              ctrl.preLoadedExternalFieldNames[obj.doc._id] = obj.doc.name || obj.doc.title;
            });
          });
        });
    }

    function loadGoalFieldExists(fields) {
      ctrl.goalFieldExists = _.find(fields, function(field) {
        return field.type === 'goal';
      });
    }

    EventTypes.getForEdit($stateParams.eventTypeId)
      .then(function(data) {
        ctrl.eventTypeObject = new EventType(data);
        ctrl.eventType = ctrl.eventTypeObject.doc;
        ctrl.origDoc = angular.copy(ctrl.eventType);
        ctrl.section = _.find(ctrl.eventType.sections, { _id: $stateParams.sectionId });
        ctrl.showDelete = true;
        ctrl.canEdit = ctrl.eventType.state === 'draft';
        ctrl.isFirstSection = ctrl.eventType.sections[0]._id === ctrl.section._id;
        // Migrate editable on the fly
        if (ctrl.section.editable) {
          ctrl.section.editableRoles = ctrl.section.editableRoles || [];
          if (ctrl.section.editableRoles.indexOf('system:author') === -1) {
            ctrl.section.editableRoles.push('system:author');
          }
          delete ctrl.section.editable;
        }
        ctrl.validateSection();
        ctrl.visibilityToDOM();

        // Note: Because event type sections don't have a name, the page title will not be unique :(
        Utils.setPageTitle('Edit event type section for ' + ctrl.eventType.name);

        // Default fill on same device
        ctrl.section.fillOnSameDevice = _.assign({
          enabled: !ctrl.isFirstSection,
          defaultVisibility: 'collapsed',
          changable: true,
          confirmationType: 'default'
        }, ctrl.section.fillOnSameDevice || {});
        ctrl.fosdForm = new Form([
          {
            id: 'defaultVisibility',
            type: 'discrete',
            label: 'Default visibility of this section on same device',
            required: true,
            disabled: !ctrl.canEdit,
            options: [
              { _id: 'collapsed', key: 'collapsed', name: 'Collapsed' },
              { _id: 'expanded', key: 'expanded', name: 'Expanded' }
            ]
          },
          {
            id: 'changable',
            type: 'boolean',
            label: 'Allow user to change visibility of this section',
            disabled: !ctrl.canEdit
          },
          {
            id: 'confirmationType',
            type: 'discrete',
            label: 'Behaviour of follow up action when this section is filled on the same device',
            required: true,
            disabled: !ctrl.canEdit,
            options: [
              {
                _id: 'default',
                key: 'default',
                name: 'This section must be confirmed by the author'
              },
              {
                _id: 'none',
                key: 'none',
                name: 'No confirmation is required by the author'
              }
            ]
          }
        ]);

        loadGoalFieldExists(ctrl.section.fields);
        return preLoadExternalFieldNames(ctrl.section.fields);
      })
      .then(function() {
        if (!ctrl.section.actionTitle) {
          ctrl.section.actionTitle = ctrl.goalFieldExists ? 'Start goals' : 'Submit';
        }
        ctrl.loaded = true;
      });

    var setupFieldRestrictions = function() {
      var roles = ctrl.section.filledBy;
      if (!_.isArray(roles)) {
        return;
      }

      ctrl.disallowAnonymize = roles.indexOf('system:anonymous-external') > -1;

      if (ctrl.disallowAnonymize) {
        ctrl.section.anonymize = true;
        ctrl.section.multiSource = true;
      }

      // Detect invitability
      if (ctrl.roles === undefined) {
        return;
      }

      ctrl.showReviewableRoles = _.chain(roles)
        .map(function(roleId) {
          var role = _.find(ctrl.roles, { id: roleId });
          if (role !== undefined && role.doc._id === 'system:anonymous-external') {
            return true;
          }

          // This is temporarily disabled until we we have that implemented
          // So far only CCF can be approved via the admin dashboard
          return false;
          // return role.doc.invitable;
        })
        .reduce(function(result, value) {
          return result || value;
        }, false)
        .value();
    };

    function updatefilledBySettings() {
      // check what's been added/deleted
      var filledBySettings = ctrl.section.filledBySettings || [];

      // add what's been added
      var filledBySettingsRoleIds = _.map(filledBySettings, function(setting) {
        return setting.roleId;
      });
      var added = _.filter(ctrl.section.filledBy, function(roleId) {
        return _.indexOf(filledBySettingsRoleIds, roleId) === -1;
      });

      _.forEach(added, function(roleId) {
        filledBySettings.push({
          roleId: roleId,
          whenIsApprovalNeeded: 'never'
        });
      });

      // delete what's been deleted
      filledBySettings = _.filter(filledBySettings, function(setting) {
        return _.indexOf(ctrl.section.filledBy, setting.roleId) > -1;
      });

      ctrl.section.filledBySettings = filledBySettings;
    }

    function updateInvitationSettings() {
      var invitable = _.intersection(ctrl.invitableRoles, ctrl.section.filledBy);
      ctrl.invitable = invitable;
      if (invitable.length === 0) {
        ctrl.invitableForm = undefined;
        ctrl.section.invitationSettings = {};
        return;
      }

      ctrl.section.invitationSettings = _.assignIn(
        { requireAccount: true, whenIsApprovalNeeded: 'always' },
        ctrl.section.invitationSettings
      );
      ctrl.invitableForm = new Form([
        {
          id: 'requireAccount',
          type: 'discrete',
          label: 'Require account?',
          required: true,
          disabled: !ctrl.canEdit,
          options: _.map([true, false], function(opt) {
            var name = opt ? 'Yes' : 'No';
            return { _id: opt, key: opt, name: name };
          })
        },
        {
          id: 'whenIsApprovalNeeded',
          type: 'discrete',
          label: 'When is approval needed?',
          required: true,
          disabled: !ctrl.canEdit,
          options: [
            { _id: 'always', key: 'always', name: 'Always' },
            { _id: 'never', key: 'never', name: 'Never' },
            {
              _id: 'userWithoutAccount',
              key: 'userWithoutAccount',
              name: 'Only when users submit without registering for an account'
            }
          ]
        },
        {
          id: 'rolesThatCanApprove',
          type: 'discrete_multiple',
          label: 'Who can approve the responses?',
          required: true,
          options: _.map(ctrl.roles, function(item) {
            return { _id: item.doc._id, key: item.doc._id, name: item.doc.title };
          }),
          expressionProperties: {
            hide: function(_$viewValue, _$modelValue, scope) {
              var value = scope.model.whenIsApprovalNeeded;
              return value === 'never' || _.isUndefined(value);
            }
          }
        }
      ]);
    }

    function setupWatches() {
      $scope.$watchCollection('sectionCtrl.section.filledBy', function() {
        setupFieldRestrictions();
        updatefilledBySettings();
        updateInvitationSettings();
      });
    }

    // Setup Roles
    ctrl.roles = [];
    ctrl.invitableRoles = [];

    var kzOpts = { cached: true };
    Roles.findAll({ includeSystemRoles: true }, {}, kzOpts)
      .then(function(data) {
        ctrl.roles = _.filter(data, function(item) {
          return ['system:system', 'system:admin', 'system:superadmin', 'system:pending-user']
            .indexOf(item.id) === -1;
        });
        ctrl.invitableRoles = _.chain(data)
          .filter(function(item) {
            return item.doc.invitable;
          })
          .map(function(item) {
            return item.doc._id;
          })
          .value();

        ctrl.editableRoles = _.map(data, function(role) {
          return { _id: role.doc._id, name: role.doc.title };
        });

        ctrl.editableRoles = _.sortBy(ctrl.editableRoles, 'name');

        ctrl.editableRoles.unshift({
          _id: 'system:author',
          name: 'Section author'
        });
      })
      .then(function() {
        setupWatches();
      });

    $scope.validateFilledBy = function(value) {
      if (_.isEmpty(value)) {
        return false;
      }

      return !(value.length > 1 && value.indexOf('system:anonymous-external') !== -1);
    };

    var remove = function() {
      _.remove(ctrl.eventType.sections, function(section) {
        return section._id === $stateParams.sectionId;
      });
      EventTypes.save(ctrl.eventType)
        .then(function() {
          Notify.success('Event type has been saved');
          $state.go('epf.event-types.edit', { id: $stateParams.eventTypeId });
        });
    };

    ctrl.cancel = function() {
      $state.go('epf.event-types.edit', { id: $stateParams.eventTypeId });
    };

    ctrl._save = function(isValid, options) {
      options = options || {};
      ctrl.formIsSubmitted = true;
      if (!options.force && ctrl.origDoc && _.isEqual(ctrl.origDoc, ctrl.eventType)) {
        return $q.when();
      }

      if (isValid) {
        ctrl.visibilityToModel();
        ctrl.eventType.type = 'eventType'; // Make sure this is eventType and not something else
        if (!ctrl.eventType.state) {
          ctrl.eventType.state = 'draft';
        }

        if (options.validateOnly) {
          return $q.when();
        }

        return EventTypes.save(ctrl.eventType)
          .then(function() {
            Notify.success('Event type has been saved');
          });
      }

      return $q.reject({
        status: 400,
        message: 'Please ensure all mandatory fields are completed and all errors corrected.'
      });
    };

    ctrl.save = function(isValid) {
      return ctrl._save(isValid, { force: true })
        .then(function() {
          $state.go('epf.event-types.edit', { id: $stateParams.eventTypeId });
        })
        .catch(Utils.showError);
    };

    ctrl.remove = function() {
      Utils.swal({
        title: 'Are you sure you want to remove this section?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      },
      function(isConfirm) {
        if (isConfirm) {
          remove();
        }
      });
    };

    /* Handle event type section form fields */
    ctrl.removeEventTypeSectionField = function(fieldId) {
      Utils.swal({
        title: 'Are you sure you want to remove this field?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      },
      function(isConfirm) {
        if (isConfirm) {
          _.remove(ctrl.section.fields, function(field) { return field._id === fieldId; });
          loadGoalFieldExists(ctrl.section.fields);
        }
      });
    };

    /* Handle event type form sections */
    ctrl.addEventTypeFormSection = function(isValid) {
      if (!isValid) {
        return;
      }

      var section = { _id: Utils.guid(), filledBy: '', fields: [] };
      ctrl.eventType.sections = ctrl.eventType.sections ? ctrl.eventType.sections : [];
      ctrl.eventType.sections.push(section);
      ctrl._save(isValid)
        .then(function() {
          $state.go('epf.event-types.section', {
            eventTypeId: ctrl.eventType._id,
            sectionId: section._id
          });
        })
        .catch(Utils.showError);
    };

    ctrl.addEventTypeSectionField = function(isValid, fieldType) {
      ctrl._save(isValid, { validateOnly: true })
        .then(function() {
          var fieldId = Utils.guid(),
              field = {
                _id: fieldId,
                type: fieldType,
                saved: false
              },
              goTo = 'epf.event-types.field';

          if (fieldType === 'text') {
            field.textType = '';
            field.content = '';
          } else if (fieldType === 'goal') {
            loadGoalFieldExists(ctrl.section.fields);
            if (ctrl.goalFieldExists) {
              return $q.reject({
                status: 400,
                message: 'You cannot add more than one goalset onto the same section'
              });
            }
            field.definition = [];
          } else if (fieldType === 'signature') {
            // This is empty
          } else {
            field[fieldType] = '';
          }

          if (fieldType === 'customField') {
            goTo = 'epf.event-types.custom-field';
            return ctrl._save(isValid)
              .then(function() {
                return { goTo: goTo, field: field };
              });
          }

          ctrl.section.fields.push(field);
          return ctrl._save(isValid)
            .then(function() {
              return { goTo: goTo, field: field };
            });
        })
        .then(function(res) {
          $state.go(res.goTo, {
            eventTypeId: $stateParams.eventTypeId,
            sectionId: $stateParams.sectionId,
            fieldId: res.field._id
          });
        })
        .catch(Utils.showError);
    };

    ctrl.showPendingWarning = function() {
      var invitationApproval = (ctrl.section.invitationSettings || {}).whenIsApprovalNeeded;
      if (['always', 'userWithoutAccount'].indexOf(invitationApproval) !== -1) {
        return true;
      }

      return _.some(ctrl.section.filledBySettings || [], { whenIsApprovalNeeded: 'always' });
    };

    ctrl.validateSection = function() {
      return ctrl.eventTypeObject.validateSection(ctrl.section._id)
        .then(function(errors) {
          ctrl.errors = errors;
        });
    };

    /* Handle section visibility - the UI has a different format to backend
     * as backend is more general.
     *
     * This is done so that when section is loaded, it creates model for UI
     * and turned back when saving the section
     */
    ctrl.visibilityToDOM = function() {
      var section = ctrl.section;
      var skipCondition = section.skipCondition || {};
      var model = {};
      var subSkip = skipCondition;

      var oppref = '';
      if (skipCondition.operator === 'not') {
        oppref = 'not';
        subSkip = skipCondition.child;
      }

      model.visibilityType = subSkip.operator || 'never';

      if (model.visibilityType !== 'never') {
        model.visibilityType = oppref + model.visibilityType;
      }

      var conds = [];
      _.forEach(subSkip.children || [], function(cond) {
        var exp = cond.expression || {};
        conds.push({
          section: exp.section,
          field: exp.field,
          value: exp.values
        });
      });
      model.conds = conds;
      ctrl.visibilityModel = model;
    };

    ctrl.visibilityToModel = function() {
      var model = ctrl.visibilityModel;
      if (model.visibilityType === 'never') {
        delete ctrl.section.skipCondition;
      } else {
        var op = ['notor', 'or'].indexOf(model.visibilityType) !== -1 ? 'or' : 'and';
        var neg = ['notor', 'notand'].indexOf(model.visibilityType) !== -1;

        var skipCondition = {
          type: 'operator',
          operator: op,
          children: _.map(model.conds, function(cond) {
            return {
              type: 'expression',
              expression: {
                type: 'sectionField',
                section: cond.section,
                field: cond.field,
                values: cond.value
              }
            };
          })
        };

        if (neg) {
          skipCondition = {
            type: 'operator',
            operator: 'not',
            child: skipCondition
          };
        }

        ctrl.section.skipCondition = skipCondition;
      }
    };

    $scope.$watch('sectionCtrl.section.multiSource', function(value) {
      if (value === undefined) {
        return;
      }
      if (!value) {
        if (ctrl.roles.indexOf('system:timeline-owner') === -1) {
          ctrl.roles.unshift({
            key: 'system:timeline-owner',
            id: 'system:timeline-owner',
            doc: {
              _id: 'system:timeline-owner',
              title: 'Timeline owner',
              description: '',
              roles: []
            }
          });
        }
      } else {
        ctrl.section.filledBy = _.without(ctrl.section.filledBy, 'system:timeline-owner');
        _.remove(ctrl.roles, { key: 'system:timeline-owner' });
        ctrl.section.fillOnSameDevice.enabled = false;
      }
    });

    $scope.$watch('sectionCtrl.section.fillOnSameDevice.defaultVisibility', function(vis) {
      if (vis === 'collapsed') {
        ctrl.section.fillOnSameDevice.changable = true;
      }
    });
  }

  EventTypeSectionController.$inject = [
    '$q',
    '$state',
    '$stateParams',
    '$scope',
    'BlueprintsService',
    'EventTypeFactory',
    'EventTypesService',
    'RelationsService',
    'RolesService',
    'FormsService',
    'NotifyService',
    'UtilsService'
  ];

  angular.module('component.eventTypes')
    .controller('EventTypeSectionController', EventTypeSectionController);
})();
